import { Component, OnInit, Input, Output, EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DataApiService } from './../../../services/data-api/data-api.service';
import { TasksService } from './../../../services/tasks/tasks.service';
import { Notification, NotificationsService } from './../../../services/notifications/notifications.service';
import * as saveToCSV from 'file-saver';

@Component({
  selector: 'dialog-notification-detail',
  templateUrl: './dialog-notification-detail.component.html',
  styleUrls: ['./dialog-notification-detail.component.scss'],
})
@Injectable()
export class DialogNotificationDetail implements OnInit {
  @Input() id: any;
  @Input() notification: Notification;
  @Input() table: string;
  @Input() appointmentsDeferred: any[];
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();

  destroy$: Subject<any> = new Subject();

  errors: any = {};

  constructor(
    private dataApiService: DataApiService,
    private tasksService: TasksService,
    private notificationsService: NotificationsService
    ) { }

  ngOnInit() { }

  close(): void {
    this.closeDialog.emit(this.id);
  }

  isObject(data) {
    return typeof data === 'object' ? JSON.stringify(data) : data;
  }

  onCancelSheetReload() {
    this.notificationsService.removeById(this.notification.id)
    this.close()
  }

  onTableToCSV() {
    let headers = this.notification.detail.detail_table.headers.join('|')
    let rows = this.notification.detail.detail_table.data.map((data) => data.map((x)=>JSON.stringify(x)).join('|')).join('\n');

    let csvData = headers + '\n' + rows

    const blob = new Blob([csvData], { type: 'text/csv'});
    let fileName = this.notification.class + '_' + this.notification.detail.detail_table.action_reload_sheet
    saveToCSV.saveAs(blob, fileName);
  }

  onSheetReload() {
    this.notificationsService.removeById(this.notification.id)
    this.tasksService.reloadSheet(this.notification.detail.detail_table.action_reload_sheet, {skipCheckDeclared: 'true'});
    this.close()
  }
}
